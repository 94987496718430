import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import MainCard from "components/layout/MainCard/MainCard";
import Breadcrumbs from "../../components/layout/BreadCrumbs/Breadcrumbs";
import LoadingWrapper from "../../components/Loading/LoadingWrapper";
import ProductDetailsSlider from "../../components/products/ProductDetailsSlider";
import useProductFlags from "../../hooks/useProductFlags";
import BasicTable from "./BasicTable/BasicTable";
import Footer from "./Footer/Footer";
import classes from "./ProductPage.module.scss";
import useAPI from "../../hooks/useAPI";
import CatalogueAside from "../Catalogue/Aside/CatalogueAside";
import Services from "./Services/Services";
import DescriptionTabs from "./DescriptionTabs/DescriptionTabs";
import Stickers from "./Stickers/Stickers";
import { useEffect, useState } from "react";
import ProductItems from "../Catalogue/Products/ProductItems/ProductItems";
import scss from "./BasicTable/BasicTable.module.scss";
import Variants from "../../components/Variants/Variants";

/**
 * Single product page.
 */
const ProductPage = () => {
    const api = useAPI();
    const productFlags = useProductFlags();
    const { productId } = useParams();
    // Easy access to API
    const execute = (part: string) => async () => {
        return await api.get(`/product-details/${part}/${productId}`);
    };
    const executeList = (part: string, payload: {}) => () =>
        api.list(`/product-details/${part}/${productId}`, payload).then((response) => {
            return response?.items;
        });
    // Load the product details
    const { data: basic, ...basicQuery } = useQuery(
        [`Product:${productId}:Basic`],
        async () => {
            return await api.get(`/product-details/basic-data/${productId}`).then((res) => res);
        },
        {
            cacheTime: 0,
            staleTime: 0,
        }
    );
    const { data: seo, ...seoQuery } = useQuery([`Product:${productId}:Seo`, productId], execute("seo"));
    const { data: gallery, ...galleryQuery } = useQuery([`Product:${productId}:Gallery`, productId], execute("gallery"));
    const { data: specification, ...specificationQuery } = useQuery([`Product:${productId}:Specification`, productId], execute("specification"));
    const { data: description, ...descriptionQuery } = useQuery([`Product:${productId}:Description`, productId], execute("description"));
    const { data: declaration, ...declarationQuery } = useQuery([`Product:${productId}:Declaration`, productId], execute("declaration"));
    const { data: breadcrumbs, ...breadcrumbsQuery } = useQuery([`Product:${productId}:Breadcrumbs`, productId], execute("breadcrumbs"));
    const { data: technicalDoc, ...technicalDocQuery } = useQuery([`Product:${productId}:TechnicalDoc`, productId], executeList("technical-doc"));
    const { data: instructionDoc, ...instructionDocQuery } = useQuery([`Product:${productId}:InstructionDoc`, productId], executeList("instruction-doc"));
    const { data: certificateDoc, ...certificateDocQuery } = useQuery([`Product:${productId}:CertificateDoc`, productId], executeList("certificate-doc"));

    const { data: recommended, ...recommendedQuery } = useQuery([`Product:${productId}:Recommended`, productId], executeList("recommended", { limit: 4 }));
    const { data: crosssell, ...crossSellQuery } = useQuery([`Product:${productId}:CrossSell`, productId], executeList("cross-sell", { limit: 4 }));
    const { data: upsell, ...upSellQuery } = useQuery([`Product:${productId}:UpSell`, productId], executeList("up-sell", { limit: 4 }));

    // All must be loaded
    const isLoading = basicQuery.isLoading || seoQuery.isLoading || galleryQuery.isLoading;
    const isDescriptionLodaing =
        specificationQuery.isLoading || descriptionQuery.isLoading || declarationQuery.isLoading || technicalDocQuery.isLoading || instructionDocQuery.isLoading || certificateDocQuery.isLoading;

    const productType = basic?.type ?? "single";

    // Build the product
    const product = !isLoading ? { ...basic?.data, seo, gallery: gallery?.gallery } : null;

    const productDescription = !isDescriptionLodaing ? { specification, description, declaration, technicalDoc, instructionDoc, certificateDoc } : null;
    // Append the main image to gallery
    if (product && product.image) {
        product.gallery = product.gallery ?? [];
    }

    // Handle wishlist
    const inWishlist = productFlags.wishlist.includes(product?.id);

    let crumbs = [];
    let path = breadcrumbs?.start?.slug;
    crumbs[0] = { name: "Početna", url: "/pocetna" };
    path += "/katalog/kategorija";
    for (const item of breadcrumbs?.steps ?? []) {
        path += "/" + item.slug;
        crumbs.push({ name: item.name, url: path });
    }

    crumbs.push({ name: breadcrumbs?.end?.name, url: "#" });
    const [productVariant, setProductVariant] = useState(null);

    const updateProductVariant = (newProduct) => {
        setProductVariant({
            ...newProduct,
            price: {
                ...newProduct?.price,
                max: [],
                min: [],
            },
        });
    };

    const [url, setURL] = useState(null);

    const handleURLChange = (url) => {
        setURL(url);
    };
    useEffect(() => {
        if (url) {
            window.history.pushState(null, null, url);
        }
    }, [url]);

    useEffect(() => {
        if (productVariant?.basic_data?.id_product_parent !== basic?.data?.item?.basic_data?.id_product) {
            setProductVariant(null);
            const timeout = setTimeout(() => {
                basicQuery.refetch();
            }, 500);
            return () => clearTimeout(timeout);
        }
    }, [productId]);

    return (
        <MainCard>
            {/* The list of available categories */}
            <CatalogueAside />

            {/* Show the product */}
            <div className={classes.wrapper}>
                <LoadingWrapper isLoading={!product}>
                    {/* Breadcrumbs */}
                    <div className="mobile-hidden">
                        <Breadcrumbs crumbs={crumbs} />
                    </div>

                    {/* Product details */}
                    {basic?.data?.item?.slug !== undefined && !isLoading ? (
                        <section className={classes.productWrapper}>
                            {/* Main image and the gallery */}
                            <div className={classes.gallery}>
                                <Stickers stickers={gallery?.stickers} />
                                <ProductDetailsSlider product={product} />
                            </div>
                            <div className={classes.hero}>
                                {!basicQuery?.isLoading && (
                                    <>
                                        <div>
                                            <h5 key={basic?.data?.item?.basic_data?.id_product} className={classes.title}>
                                                {!productVariant?.id ? basic?.data?.item?.basic_data?.name : productVariant?.basic_data?.name}
                                            </h5>

                                            <BasicTable
                                                product={product}
                                                productId={basic?.data?.item?.slug}
                                                updateProductVariant={updateProductVariant}
                                                handleURLChange={handleURLChange}
                                                productVariant={productVariant}
                                                basic={basic}
                                            />
                                            {basic?.product_type === "variant" && (
                                                <div className={scss.wrapper}>
                                                    <Variants
                                                        firstVariantOption={false}
                                                        product={basic?.data}
                                                        productVariant={productVariant}
                                                        productSlug={productId}
                                                        handleURLChange={handleURLChange}
                                                        updateProductVariant={updateProductVariant}
                                                    />
                                                </div>
                                            )}
                                        </div>

                                        <Footer product={product} productVariant={productVariant} inWishlist={inWishlist} />

                                        <Services />
                                    </>
                                )}
                            </div>
                        </section>
                    ) : (
                        <div className={classes.noitem}>Traženi proizvod nije dostupan.</div>
                    )}
                </LoadingWrapper>
                {productDescription && <DescriptionTabs product={productDescription} />}
                <div className={`d-flex flex-column gap-5 mt-5`}>
                    {recommended && recommended?.length ? (
                        <div className={`w-100`}>
                            <p className={classes.title}>Preporučeno za Vas</p>
                            <ProductItems products={recommended} view={"grid"} />
                        </div>
                    ) : (
                        ""
                    )}
                    {crosssell && crosssell?.length ? (
                        <div className={`w-100`}>
                            <p className={classes.title}>Možda će Vam se dopasti</p>
                            <ProductItems products={crosssell} view={"grid"} />
                        </div>
                    ) : (
                        ""
                    )}
                    {upsell && upsell?.length ? (
                        <div className={`w-100`}>
                            <p className={classes.title}>Dodatna ponuda</p>
                            <ProductItems products={upsell} view={"grid"} />
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </MainCard>
    );
};

export default ProductPage;
