import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { useState } from "react";
import styles from "./CategoriesModal.module.scss";
import LinkList from "../LinksList/LinkList";
import { useQuery } from "react-query";
import useAPI from "hooks/useAPI";
import { Link, useNavigate } from "react-router-dom";
import Sitemap from "routes/sitemap";
import icons from "helpers/icons";
import { toast } from "react-toastify";
import scss from "../../../../pages/Catalogue/Aside/CatalogueAside.module.scss";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import CelebrationIcon from "@mui/icons-material/Celebration";
import Box from "@mui/material/Box";

const CategoriesModal = ({ openModal, setOpenModal }) => {
    const [search, setSearch] = useState("");
    const api = useAPI();
    const navigate = useNavigate();

    const submitHandler = (event) => {
        event.preventDefault();
        if (search?.length >= 3) {
            navigate(Sitemap.catalogue.search + "/" + search);
            setOpenModal(false);
        } else {
            toast.error("Uneti najmanje 3 karaktera");
        }
    };

    const { data } = useQuery(["Products.CategoryTree"], () => api.get("/categories/product/tree"));
    const { data: landingPages } = useQuery(["PromoPage"], () => api.list("/landing-pages/list"));

    const { data: newPages } = useQuery(["NewPage"], () => api.list("/products/new-in/list"));
    const generateLinks = (categories, startPath) => {
        if (categories == null || !Array.isArray(categories)) return;

        return categories.map((category) => {
            let path = `${startPath}/${category.slug}`;
            return { icon: category.icon, label: category.name, to: path, children: generateLinks(category.children, path) };
        });
    };

    const links = generateLinks(data, "/katalog/kategorija");

    /* const links = (data ?? []).map((category) => {
        return { icon: searchImg, label: category.name, to: category.slug, cildren: category.children };
    }); */

    return (
        <ModalWrapper openModal={openModal} setOpenModal={setOpenModal} title="Pregled proizvoda">
            <form className={styles.search} onSubmit={submitHandler}>
                <input type="text" placeholder="PRETRAŽITE PROIZVODE" className={styles.searchInput} value={search} onChange={(e) => setSearch(e.target.value)} />
                <button type="submit" className={styles.searchButton}>
                    <img src={icons.search} alt="search" onClick={submitHandler} />
                </button>
            </form>
            <Box sx={{ padding: "0.25rem 0 0.25rem 0" }}>
                {newPages?.items?.length > 0 && (
                    <Link className={`d-flex ${scss.promo} gap-1  text-decoration-none text-white flex align-items-center`} to={`/novo`}>
                        {/*<img src="/images/discount.png" alt="" width={30} height={30} />*/}
                        <NewReleasesIcon sx={{ fontSize: "20px", color: "var(--primary-color)" }} />
                        <span>Novo u ponudi</span>
                    </Link>
                )}
                {landingPages?.items?.length > 0 && (
                    <Link className={`d-flex ${scss.promo} gap-1  text-decoration-none text-white flex align-items-center`} to={`/promo`}>
                        {/*<img src="/images/discount.png" alt="" width={30} height={30} />*/}
                        <CelebrationIcon sx={{ fontSize: "20px", color: "var(--primary-color)" }} />
                        <span>Promocije</span>
                    </Link>
                )}
                {/* {actionPages?.items?.length > 0 && ( */}
                {/* <Link className={`d-flex ${scss.promo} gap-1  text-decoration-none text-white flex align-items-center`} to={`/akcija`}> */}
                {/*<img src="/images/discount.png" alt="" width={30} height={30} />*/}
                {/* <FormatColorTextIcon sx={{ fontSize: "20px", color: "var(--primary-color)" }} /> */}
                {/* <span>Akcije</span> */}
                {/* </Link> */}
                {/* )} */}
            </Box>
            <LinkList links={links} setOpenModal={setOpenModal} />
        </ModalWrapper>
    );
};

export default CategoriesModal;
