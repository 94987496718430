import { useEffect, useState } from "react";
import scss from "./PlusMinusInput.module.scss";
import { toast } from "react-toastify";

const roundCustom = (num) => {
    const decimalPart = num % 1;

    if (decimalPart < 0.01) {
        return Math.round(num);
    } else {
        return parseFloat(Number(num).toFixed(2));
    }
};

/**
 * Number input with styles plus and minus buttons.
 */
const PlusMinusInput = ({ behaviours, quantity, max, setQuantity, debounceId = null, className }) => {
    const allow_decimals = behaviours?.display.allow_decimals;
    const default_loop_quantity = behaviours?.default_loop_quantity;

    const intQuantity = allow_decimals ? quantity : Math.floor(quantity);

    const [tempValue, setTempValue] = useState(intQuantity);

    useEffect(() => {
        if (quantity !== tempValue) {
            setTempValue(quantity);
        }
    }, [quantity]);

    const [config, setConfig] = useState();
    useEffect(() => {
        if (typeof document !== "undefined") {
            const inventory_config = localStorage.getItem("configuration_inventories");
            if (inventory_config) {
                let temp = JSON.parse(inventory_config);
                temp = temp?.filter((item) => item?.slug === "check_requested_inventory_product_quantity");
                if (temp?.length > 0) {
                    setConfig(Boolean(Number(temp[0]?.value)));
                }
            }
        }
    }, []);

    useEffect(() => {
        const handler = setTimeout(() => {
            if (tempValue === quantity) {
                return;
            }

            if (tempValue === 0 || tempValue === "" || tempValue < default_loop_quantity) {
                setQuantity(default_loop_quantity);
                setTempValue(default_loop_quantity);
                return;
            }

            let clickedOnIncrease = roundCustom(tempValue) / default_loop_quantity;
            const decimalPart = clickedOnIncrease % 1;
            if (decimalPart < 0.01) {
                clickedOnIncrease = Math.round(clickedOnIncrease);
            }
            clickedOnIncrease = Math.ceil(clickedOnIncrease);

            let newValue = clickedOnIncrease * default_loop_quantity;
            if (!Number.isInteger(newValue)) {
                newValue = parseFloat(newValue).toFixed(2);
            }

            setQuantity(newValue);
            setTempValue(newValue);
            // }
        }, 500);

        return () => clearTimeout(handler);
    }, [tempValue]);

    const handleChange = (e) => {
        let newValue = e.target.value;
        const regex = allow_decimals ? /^\d*\.?\d*$/ : /^\d*$/;
        if (regex.test(newValue)) {
            setTempValue(newValue);
        }
    };

    // If the values are not yet synchronized mark the input as dirty
    const wrapperClass = [scss.wrapper, className];
    if (debounceId && tempValue !== intQuantity) {
        wrapperClass.push(scss.dirty);
    }

    const countQuantity = ({ defaultLoopQuantity, currentQuantity, isIncreased }) => {
        let clickedOnIncrease = currentQuantity / defaultLoopQuantity;
        if (clickedOnIncrease < 1) return;

        if (isIncreased) {
            clickedOnIncrease = clickedOnIncrease + 1;
        } else if (clickedOnIncrease > 1) {
            clickedOnIncrease = clickedOnIncrease - 1;
        }

        if (quantity > max && config) {
            toast.error("Nema dovoljno proizvoda na stanju!");
            return;
        }
        let newValue = clickedOnIncrease * defaultLoopQuantity;
        if (!Number.isInteger(newValue)) {
            newValue = parseFloat(newValue).toFixed(2);
        }

        setTempValue(newValue);
    };

    return (
        <div className={wrapperClass.join(" ")} style={{ position: "relative" }}>
            <span className={scss.button} onClick={() => countQuantity({ defaultLoopQuantity: default_loop_quantity, currentQuantity: quantity, isIncreased: false })}>
                -
            </span>
            <input
                maxLength="100"
                value={tempValue}
                onChange={handleChange}
                onClick={(e) => {
                    e.preventDefault();
                    e.target.select();
                }}
            />
            <span className={scss.button} onClick={() => countQuantity({ defaultLoopQuantity: default_loop_quantity, currentQuantity: quantity, isIncreased: true })}>
                +
            </span>

            {/* Indicator that the value is being synchronized */}
            <i className="fa fa-circle-o-notch fa-spin" />
        </div>
    );
};

export default PlusMinusInput;
