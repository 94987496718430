import { Link } from "react-router-dom";

import wishListImg from "../../../../../assets/images/icons/heart.png";
import AddToCartButton from "../../../../../components/Form/AddToCartButton/AddToCartButton";
import FormWrapper, { preventDefault } from "../../../../../components/Form/FormWrapper";
import PlusMinusInput from "../../../../../components/Form/PlusMinusInput/PlusMinusInput";
import { missingImageURL } from "../../../../../helpers/images";
import useAPI from "../../../../../hooks/useAPI";
import useProductFlags from "../../../../../hooks/useProductFlags";
import Sitemap from "../../../../../routes/sitemap";
import __ from "../../../../../config/translations/serbian";
import { useState } from "react";
import Stickers from "./Stickers";
import icons from "helpers/icons";
import scss from "./ProductGridItem.module.scss";
import { toast } from "react-toastify";
import { Prices } from "../../../../../components/prices";

/**
 * Component that shows a single product in a grid.
 *
 * @param {{}} product The product to show.
 *
 * @return {JSX.Element}
 * @constructor
 */
const ProductGridItem = ({ product }) => {
    const productFlags = useProductFlags();
    const api = useAPI();
    const [loading, setLoading] = useState(false);

    // Track the quantity to add to the cart
    const [quantity, setQuantity] = useState(product?.behaviours?.cart?.default_loop_quantity ?? 1);

    const getQuantity = () => {
        if (quantity === 0) {
            setQuantity(product?.behaviours?.cart?.default_loop_quantity ?? 1);
            return product?.behaviours?.cart?.default_loop_quantity ?? 1;
        }
        return quantity;
    };

    // Handle wishlist
    const inWishlist = productFlags.wishlist.includes(product);
    const wishlistClasses = [scss.wishlist];
    if (inWishlist) {
        wishlistClasses.push(scss.active);
    }

    return (
        <div className={scss.wrapper}>
            <div className={scss.product}>
                <div className={scss.productImageContainer}>
                    {/* Wishlist */}
                    <button onClick={preventDefault(() => api.globalAddToWishlist(product?.basic_data?.id_product, !inWishlist))} className={wishlistClasses.join(" ")}>
                        <img src={wishListImg} alt="" />
                    </button>
                    {/* mobile cart */}

                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            setLoading(true);
                            api.globalAddToCart(product?.id, 1, loading, setLoading);
                        }}
                        className={scss.mobileCart}
                    >
                        <img src={icons.cart} alt="" />
                    </button>

                    {/* Additional stickers */}
                    {product?.stickers?.length > 0 && <Stickers stickers={product?.stickers} />}

                    {/* Image */}
                    <Link to={Sitemap.catalogue.productDetails + "/" + product?.id} className={scss.productImage}>
                        <div className={scss.imageContainer}>
                            <img src={product?.images[0] ?? missingImageURL()} alt={product?.name} className={`${scss.image} ${product?.images[1] && scss.productImage1}`} />
                            {product?.images[1] && <img src={product?.images[1]} alt={product?.name} className={`${scss.image} ${scss.productImage2}`} />}
                        </div>
                    </Link>
                </div>

                <Link to={Sitemap.catalogue.productDetails + "/" + product?.id} className={scss.product}>
                    <span className={`${scss.category} mobile-hidden`}>{product?.categories[0]?.name}</span>
                    <span className={scss.name}>{product?.basic_data?.name}</span>

                    <span className={scss.code}>
                        <span className={scss.codeTitle}>{__.Product.Column.Code}:</span>
                        <span className={scss.codeInt}>{product?.basic_data?.sku}</span>
                    </span>

                    <Prices price={product?.price} inventory={product?.inventory} />
                </Link>
            </div>

            {/* Add to cart */}
            <FormWrapper
                className="row pt-2"
                onSubmit={() => {
                    setLoading(true);
                    // if (isPlusMinusInputEmpty) {
                    //     toast.warn("Molimo unesite količinu!");
                    //     setLoading(false);
                    // } else {
                    api.globalAddToCart(product.id, getQuantity(), loading, setLoading);
                    setQuantity(product?.behaviours?.cart?.default_loop_quantity);
                    // }
                }}
            >
                <div className={product?.product_type === "variant" ? "col-12" : "col-6"}>
                    {product?.product_type === "variant" ? (
                        <Link className={`text-decoration-none`} to={`/katalog/artikal/${product?.basic_data?.id_product}`}>
                            <p className={`${scss.button1} text-center`}>Više opcija</p>
                        </Link>
                    ) : product?.price?.price_defined && product?.price?.price?.original > 0 && product?.inventory?.inventory_defined ? (
                        <PlusMinusInput behaviours={product?.behaviours?.cart} max={+product?.inventory?.amount} quantity={quantity} setQuantity={setQuantity} />
                    ) : null}
                </div>
                <div className={product?.price?.price_defined && product?.inventory?.inventory_defined && product?.price?.price?.original > 0 ? `col-6` : `col-11 p-0 m-auto`}>
                    {product?.price?.price_defined && product?.price?.price?.original > 0 && product?.inventory?.inventory_defined ? (
                        product?.product_type === "variant" ? null : (
                            <>
                                <AddToCartButton loading={loading} type={product?.inventory?.amount || product?.item?.inventory <= 0 ? "submit" : "button"} product={product} />
                            </>
                        )
                    ) : product?.product_type !== "variant" ? (
                        <>
                            <div className={`d-flex  flex-col ${scss.button2} h-100  items-center justify-content-center`}>
                                <Link to={`/kontakt?slug=${product?.slug ?? product?.id}`} className={`my-auto `}>
                                    Pošalji upit
                                </Link>
                            </div>
                        </>
                    ) : null}
                </div>
            </FormWrapper>
        </div>
    );
};

export default ProductGridItem;
