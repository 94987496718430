import { useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import MainCard from "../../components/layout/MainCard/MainCard";
import __ from "../../config/translations/serbian";
import useArray from "../../hooks/useArray";
import CatalogueAside from "../Catalogue/Aside/CatalogueAside";
import Products from "../Catalogue/Products/Products";
import useAPI from "hooks/useAPI";
import { useQuery } from "react-query";

/**
 * The main page that shows multiple products.
 */
const HomePage = () => {
    const { searchTerm } = useParams();
    const { pathname } = useLocation();
    const trimmed = pathname.trim("/");
    const parts = trimmed.split("/");

    const api = useAPI();
    const navigate = useNavigate();
    const location = useLocation();
    // The available and active filters
    const [activeFilters, setActiveFilters] = useArray([]);
    const [availableFilters, setAvailableFilters] = useState([]);
    // The category we are searching for
    const [activeCategory, setActiveCategory] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState([]);
    let title = activeCategory?.name ?? "Izdvajamo";
    let search = "";

    // Navigate to category
    const navigateToCategory = (category) => {
        navigate(pathname === "/pocetna" ? "/pocetna" : "/katalog/kategorija" + pathname === "/pocetna" ? "" : "/" + category.slug);
        setActiveCategory(category);
    };

    // The used search term
    if (searchTerm) {
        title = `${__.Catalogue.Search}: "${searchTerm}"`;
        search = searchTerm;
    }

    const [changeFilters, setChangeFilters] = useState(false);
    const [sort, setSort] = useState([
        {
            field: "has_image",
            direction: "desc",
        },
        {
            field: "new",
            direction: "desc",
        },
    ]);
    const [limit, setLimit] = useState(12);
    const [page, setPage] = useState(1);

    useQuery(
        ["config", "b2b_basic_configuration", "configuration_inventories"],
        async () => {
            return await api.get(`configuration/protected/data/b2b_basic_configuration/configuration_inventories`).then((res) => {
                if (typeof document !== "undefined") {
                    localStorage.setItem("configuration_inventories", JSON.stringify(res));
                }
            });
        },
        { refetchOnWindowFocus: false, refetchInterval: 15 * 60 * 1000 }
    );

    return (
        <MainCard>
            {/* The list of available categories */}

            <CatalogueAside
                activeCategory={activeCategory}
                setActiveCategory={setActiveCategory}
                onActivateCategory={navigateToCategory}
                setActiveFilters={setActiveFilters}
                setSelectedFilters={setSelectedFilters}
                selectedFilters={selectedFilters}
                changeFilters={changeFilters}
                setChangeFilters={setChangeFilters}
            />

            <Products
                title={title}
                url={`/products/section/list/recommendation`}
                banners={true}
                search={search}
                filters={activeFilters}
                availableFilters={availableFilters}
                setActiveFilters={setActiveFilters}
                setAvailableFilters={setAvailableFilters}
                location={location?.pathname}
                limit={limit}
                setLimit={setLimit}
                sort={sort}
                showFilters={false}
                setSort={setSort}
                currentPage={page}
                setCurrentPage={setPage}
            />
        </MainCard>
    );
};

export default HomePage;
