import { useContext, useEffect, useState } from "react";
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import __ from "../../../config/translations/serbian";
import Sitemap from "../../../routes/sitemap";
import AuthContext from "../../../store/auth-context";
import userImg from "../../../assets/images/icons/profile.png";
import { GlobalQueries } from "hooks/useRefreshQuery";
import { useQuery } from "react-query";
import useAPI from "hooks/useAPI";

import scss from "./LeftSideBar.module.scss";
import CategoriesModal from "../MobileDialogs/CategoriesModal/CategoriesModal";
import UserPanelModal from "../MobileDialogs/UserPanelModal/UserPanelModal";
import icons from "helpers/icons";
import SearchModal from "../MobileDialogs/SearchModal/SearchModal";
import DeleteDialog from "components/Dialogs/DeleteDielog";

/**
 * Left side navigation.
 */
const LeftSideBar = ({ openModal, setOpenModal }) => {
    const api = useAPI();
    const navigate = useNavigate();
    const { logout } = useContext(AuthContext);
    const { pathname } = useLocation();

    /** Log the user out and show login form. */
    const logOut = () => {
        logout();
        navigate(Sitemap.auth.login);
    };

    // Load all notifications
    const { data: notifications } = useQuery([GlobalQueries.Notifications], () => api.list(`/notifications`));
    const unreadNotificationCount = notifications?.items?.filter((notification) => !notification.seen_date).length;

    // Get the class for the icon in the menu
    const iconClass = (icon) => scss.link + (icon.isActive ? " " + scss.active : "");

    const [openCategoriesModal, setOpenCategoriesModal] = useState(false);
    const [openUserModal, setOpenUserModal] = useState(false);
    const [openSearchModal, setOpenSearchModal] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    const closeAllModals = () => {
        setOpenCategoriesModal(false);
        setOpenUserModal(false);
        setOpenSearchModal(false);
    };

    const toggleUserPanel = () => {
        setOpenModal("sidebar");
        closeAllModals();
        setOpenUserModal(!openUserModal);
    };

    const toggleSearch = () => {
        setOpenModal("sidebar");
        closeAllModals();
        setOpenSearchModal(!openSearchModal);
    };

    const toggleCategories = () => {
        setOpenModal("sidebar");
        closeAllModals();
        setOpenCategoriesModal(!openCategoriesModal);
    };

    useEffect(() => {
        if (openModal !== "sidebar") {
            closeAllModals();
        }
    }, [openModal]);

    useEffect(() => {
        closeAllModals();
    }, [pathname]);

    const links = [
        { icon: icons.catalogue, label: "Početna", to: Sitemap.home, badge: null },
        { icon: icons.notifications, label: "Obaveštenja", to: Sitemap.notifications._, badge: unreadNotificationCount > 0 && <em className={scss.badge}>{unreadNotificationCount}</em> },
        { icon: icons.cart, label: "Pregled kupovina", to: Sitemap.orders._, badge: null },
        { icon: icons.analytics, label: "Analitička kartica", to: Sitemap.static.analyticCard, badge: null },
        { icon: icons.contact, label: "Kontakt", to: Sitemap.contact._, badge: null },
    ];

    return (
        <>
            <div className={`${scss.wrapper} ${scss.navDesktop}`}>
                <div className={scss.links}>
                    {links.map((link) => (
                        <NavLink to={link.to} key={link.to} className={iconClass} title={link.label}>
                            <img src={link.icon} alt="" />
                            {link.badge}
                        </NavLink>
                    ))}
                </div>

                <div className={scss.links}>
                    <NavLink to={Sitemap.profile._} className={iconClass} title={__.Company.MenuTitle}>
                        <img src={userImg} alt="" />
                    </NavLink>
                    <div className={scss.link} onClick={() => setShowDialog(true)} title={__.Login.Logout}>
                        <img src={icons.logout} alt="" />
                    </div>
                </div>
            </div>
            <div className={`${scss.wrapper} ${scss.navMobile}`}>
                <div className={scss.links}>
                    <div className={`${scss.link} ${scss.active}`} onClick={() => logOut()} title={__.Login.Logout}>
                        <img src={icons.logout} alt="" />
                    </div>
                    <Link to={`/nalog`} onClick={toggleUserPanel} className={`${scss.link} `} title={__.Company.MenuTitle}>
                        <img src={icons.user_profile} alt="" />
                    </Link>
                </div>
                <div className={scss.links}>
                    <div onClick={toggleSearch} className={`${scss.link} `} title={"Pretraga"}>
                        <img src={icons.search} alt="" />
                    </div>
                    <div onClick={toggleCategories} className={`${scss.link} ${scss.active}`} title={__.Catalogue.MenuTitle}>
                        <img src={icons.catalogue} alt="" /> <span>PROIZVODI</span>
                    </div>
                </div>
            </div>
            <CategoriesModal openModal={openCategoriesModal} setOpenModal={setOpenCategoriesModal} />
            {/*<UserPanelModal openModal={openUserModal} setOpenModal={setOpenUserModal} />*/}
            <SearchModal openModal={openSearchModal} setOpenModal={setOpenSearchModal} />
            <DeleteDialog
                openDeleteDialog={{ show: showDialog }}
                setOpenDeleteDialog={() => setShowDialog(false)}
                handleConfirm={() => {
                    logOut();
                    setShowDialog(false);
                }}
                title={"Odjava"}
                description={"Da li ste sigurni da želite da se odjavite?"}
                nameOfButtonDelete={"Odjavi se"}
                deafultIcon={false}
            />
        </>
    );
};

export default LeftSideBar;
